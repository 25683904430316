<template>
    <div class>
    <div class="container">
      <div v-if="loading" class="loader">Loading...</div>
      <div class="welcome-flow-container" v-if="!loading">
        <div class="row d-flex justify-content-center align-items-center vh-100">
          <div class="col-12 col-lg-5 order-lg-3">
            <div class="widget mb-3">
              <div class="widget-splash splash-line"></div>
              <div class="widget-body text-center">
                <h4>Congratulations</h4>
                <p>Your profile has been created</p>
                <div class="mt-5 mb-5">
                  <i class="fas fa-check"></i>
                </div>
                <p>Go to your profile dashboard and start exploring thesis topics and students open for a thesis collaboration.</p>
                <router-link to="/" class="btn btn-primary btn-block btn-lg">Go to dashboard</router-link>
                <router-link to="/collaborations" class="btn btn-block btn-lg"
                        style="border:1px solid #5A2871; color:#5A2871"
                >Explore thesis topics</router-link>
              </div>
            </div>
            <!-- /widget -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .widget .widget-body i {
    font-size: 80px;
    color: #BC1E73; 
  }
</style>